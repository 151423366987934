import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Paper, Tooltip, Menu, MenuItem, Typography } from '@mui/material'
import { ArrowDropDown, MicNone, VideocamOutlined, SpeakerOutlined } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { useSnackbar } from 'notistack'

import { SessionToolbar } from './SessionToolbar'
import { SessionVideoTile } from './SessionVideoTile'
import { useZoomSession } from './SessionProvider'
import { useConnectionStrength, useHardwareMenusEls } from './utils'

export const SessionPreCall: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { isPoorConnection, networkQuality } = useConnectionStrength()
  // Disable video if network is too slow
  useEffect(() => {
    if (isPoorConnection) setIsVideoOn(false)
  }, [isPoorConnection])

  const {
    handleCloseAll,
    handleOpenAudio,
    handleOpenSpeakers,
    handleOpenVideo,
    audioMenuProps,
    speakersMenuProps,
    videoMenuProps,
  } = useHardwareMenusEls()

  const { classes } = useStyles()
  const videoRef = useRef<HTMLVideoElement>(null)
  const {
    activeCamera,
    activeMicrophone,
    activeSpeaker,
    cameras,
    isMuted,
    isVideoOn,
    microphones,
    modal,
    setActiveCameraId,
    setActiveMicrophoneId,
    setActiveSpeakerId,
    setCameras,
    setIsMuted,
    setIsVideoOn,
    setMicrophones,
    setScreen,
    setSpeakers,
    speakers,
  } = useZoomSession()
  const videoTrackRef = useRef<any>()
  const [tooltipDismissed, setTooltipDismissed] = useState(false)

  // Get all available devices
  useEffect(() => {
    // Zoom SDK is loaded via CDN in <script> tag; typing is not available.
    // @ts-ignore
    window.WebVideoSDK.default
      .getDevices()
      .then((devices: MediaDeviceInfo[]) => {
        const cameras = devices.filter(d => d.kind === 'videoinput')
        const microphones = devices.filter(d => d.kind === 'audioinput')
        const speakers = devices.filter(d => d.kind === 'audiooutput')
        setSpeakers(speakers)
        setCameras(cameras)
        setMicrophones(microphones)
        /**
         * While speakers and microphone choices only exist in the dropdown menu in this view,
         * the camera choice is also responsible for starting the video track. Rather than just
         * setting the activeCameraId, we also start the video track if the user hasn't disabled
         * the camera prior to getDevices() resolving.
         */
        if (cameras.length) handleSwitchCamera(activeCamera?.deviceId ?? cameras[0].deviceId)
        if (microphones.length && !activeMicrophone) setActiveMicrophoneId(microphones[0].deviceId)
        if (speakers.length && !activeSpeaker) setActiveSpeakerId(speakers[0].deviceId)
      })
      .catch(e => {
        console.log('Pre-call - Failed to init video', e)
      })
  }, [])

  // Stops the camera track
  const handleStopCamera = useCallback(async () => {
    const track = videoTrackRef.current
    // This definition is missing but is viewable in the console if you log the track
    // @ts-ignore
    if (track?.isVideoStarted) return track?.stop()
  }, [])

  // Start the camera and update the track reference
  const handleStartCamera = useCallback(async (cameraId: string) => {
    // Zoom SDK is loaded via CDN in <script> tag; typing is not available.
    // @ts-ignore
    const newTrack = window.WebVideoSDK.default.createLocalVideoTrack(cameraId)
    videoTrackRef.current = newTrack

    // It's possible for hardware to fail or get stuck, which requires user intervention.
    // Show feedback to user if this happens.
    const loggerTimeout = setTimeout(() => {
      enqueueSnackbar('Failed to start video. Try selecting a different camera or refreshing.', {
        variant: 'error',
      })
    }, 5000)

    await newTrack.start(videoRef.current!).catch(e => {
      console.log(
        'Squashing error. This is likely throwing because the camera is attempting to be toggled while still initializing.',
        e
      )
      enqueueSnackbar('Camera is starting', { variant: 'info' })
    })
    clearTimeout(loggerTimeout)
  }, [])

  // Toggle the camera on and off by clicking the camera icon
  const handleToggleCamera = useCallback(
    async (on: boolean) => {
      if (on) await handleStopCamera()
      else await handleStartCamera(activeCamera?.deviceId ?? cameras[0].deviceId)
      setIsVideoOn(!on)
    },
    [activeCamera, cameras]
  )

  // Toggle between cameras via the dropdown menu
  const handleSwitchCamera = useCallback(
    async (cameraId: string) => {
      // If video is off, just switch the active camera
      if (!isVideoOn) {
        setActiveCameraId(cameraId)
        return
      }
      /**
       * Otherwise:
       * 1. Stop the current track
       * 2. Start a new the video track and start publishing
       * 3. Switch the active camera
       */
      await handleStopCamera()
      await handleStartCamera(cameraId)
      setActiveCameraId(cameraId)
    },
    [isVideoOn]
  )

  const handleJoinCall = useCallback(async () => {
    await handleStopCamera()
    setScreen('call')
  }, [])

  // Try to clean up the camera track on unmount, i.e. when exiting the video modal
  useEffect(
    () => () => {
      handleStopCamera()
    },
    []
  )

  return (
    <Grid container alignContent="center" justifyContent="center" height="100%">
      <Grid item xs={12} lg={modal ? 12 : 6}>
        <Box
          sx={{
            p: 4,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{ overflow: 'hidden', position: 'relative', width: '100%', minHeight: 200 }}
          >
            <SessionVideoTile muted={isMuted} quality={networkQuality} />
            <Tooltip
              open={isPoorConnection && !tooltipDismissed}
              title="Video disabled due to poor connection"
            >
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video ref={videoRef} className={classes.video} />
            </Tooltip>
            <Box
              sx={{
                background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.25))',
                position: 'absolute',
                bottom: 0,
                height: '25%',
                width: '100%',
              }}
            />
            <SessionToolbar
              onToggleMicrophone={muted => setIsMuted(!muted)}
              onToggleCamera={on => {
                handleToggleCamera(on)
                setTooltipDismissed(true)
              }}
            />
          </Paper>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Button onClick={handleOpenAudio}>
                <MicNone fontSize="small" />
                <Typography sx={{ mx: 1 }} variant="inherit">
                  Microphone
                </Typography>
                <ArrowDropDown fontSize="small" />
              </Button>
              <Button onClick={handleOpenVideo}>
                <VideocamOutlined fontSize="small" />
                <Typography sx={{ mx: 1 }} variant="inherit">
                  Camera
                </Typography>
                <ArrowDropDown fontSize="small" />
              </Button>
              {speakers.length ? (
                <Button onClick={handleOpenSpeakers}>
                  <SpeakerOutlined fontSize="small" />
                  <Typography sx={{ mx: 1 }} variant="inherit">
                    Speakers
                  </Typography>
                  <ArrowDropDown fontSize="small" />
                </Button>
              ) : null}
              <Menu {...audioMenuProps}>
                {microphones.map(microphone => (
                  <MenuItem
                    key={microphone.deviceId}
                    selected={activeMicrophone?.deviceId === microphone.deviceId}
                    onClick={async () => {
                      setActiveMicrophoneId(microphone.deviceId)
                      handleCloseAll()
                    }}
                  >
                    {microphone.label}
                  </MenuItem>
                ))}
              </Menu>
              <Menu {...speakersMenuProps}>
                {speakers.map(speaker => (
                  <MenuItem
                    selected={activeSpeaker?.deviceId === speaker.deviceId}
                    key={speaker.deviceId}
                    onClick={() => {
                      setActiveSpeakerId(speaker.deviceId)
                      handleCloseAll()
                    }}
                  >
                    {speaker.label}
                  </MenuItem>
                ))}
              </Menu>
              <Menu {...videoMenuProps}>
                {cameras.map(camera => (
                  <MenuItem
                    key={camera.deviceId}
                    selected={activeCamera?.deviceId === camera.deviceId}
                    onClick={() => {
                      handleSwitchCamera(camera.deviceId)
                      // setActiveCameraId(camera.deviceId)
                      handleCloseAll()
                    }}
                  >
                    {camera.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Button variant="contained" size="large" onClick={handleJoinCall}>
              Join Call
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles()(() => ({
  video: {
    display: 'block',
    width: '100%',
    position: 'relative',
    zIndex: 1,
    transform: 'scaleX(-1)',
  },
}))
